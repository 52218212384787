<template>
  <div class="about">
    <h1>This is an about page</h1>
    <Game />
  </div>
</template>

<script>
import Game from '@/components/Game'
export default {
  components: { Game },
  setup() {
    
  },
}
</script>
